<script setup lang="ts">
const { notifications, removeOne } = useNotifications()
</script>
<template>
  <div>
    <ShopNotification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
      @click:close="removeOne(notification.id)"
    />
  </div>
</template>
